import * as React from "react";
import {useEffect, useMemo} from "react";
import {hasAnyPermission, hasPermission, useCurrentUser} from "../../hooks/auth";
import {
  LINK_ANALYSIS,
  LINK_INSTITUTIONS,
  LINK_LOGOUT,
  LINK_PART_ANALYSIS,
  LINK_PART_IMPORT,
  LINK_REGISTRATION_AUTHORITIES,
  LINK_REGISTRATION_AUTHORITIES_ADMINISTRATION,
  LINK_ROOT
} from "../../constants/navigate";
import {useTranslation} from "react-i18next";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {FlagDESVG, FlagENSVG} from "@nbp/dnafe-design/svg";
import {
  CookieAcceptBanner,
  DefaultLayout,
  LanguageSwitcher,
  LoadingOverlay,
  UserMenuPopover
} from "@nbp/dnafe-material-ui/dist/components";
import {MainMenuItem} from "@nbp/dnafe-material-ui/dist/components/Menu/Menu";
import {FooterLink} from "@nbp/dnafe-material-ui/dist/components/Footer/Footer";
import {LANGUAGE_DE, LANGUAGE_EN} from "../../constants/language";
import {LanguageSwitcherItem} from "@nbp/dnafe-material-ui/dist/components/LanguageSwitcher/LanguageSwitcher";
import "./Layout.scss";
import {setUserPopover, useUserMeData, useUserMeLoading, useUserPopover} from "../../hooks/user";
import {Button, IconButton, Typography} from "@mui/material";
import {useMatomo} from "../../hooks/matomo";
import {PERMISSION} from "../../constants/permission";
import {useEvent} from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import {getMyRegistrationAuthority} from "../../hooks/registrationAuthority";
import {getMyAdminInstitution, getMyIssuerInstitution} from "../../hooks/institution";
import {useBreadcrumbValues} from "../../hooks/general";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import HomeIcon from "@mui/icons-material/Home";

const Layout = () => {
  const {t, i18n} = useTranslation();
  const {language, changeLanguage} = i18n;
  const {permissions, decodedToken} = useCurrentUser();
  const {pathname} = useLocation();
  const userMe = useUserMeData();
  const userName = decodedToken?.given_name + " " + decodedToken?.family_name;
  const usersMeLoading = useUserMeLoading();
  const navigate = useNavigate();
  const {FOOTER_LINKS_HOST} = window.env;
  const breadcrumbValues = useBreadcrumbValues();

  const {initialized, tracker} = useMatomo();

  useEffect(() => {
    if (initialized) {
      tracker.setCustomUrl(window.location.href);
      tracker.trackPageView(document.title);
    }
  }, [initialized, pathname]);

  const hidePopover = useEvent(() => {
    setUserPopover(null);
  });

  const handleLogout = useEvent(() => {
    hidePopover();
    navigate(LINK_LOGOUT);
  });

  const onUserClick = useEvent((event: Event) => {
    event.preventDefault();
    setUserPopover({anchor: event.currentTarget});
  });

  const languages: LanguageSwitcherItem[] = useMemo(() => [
    {
      language: LANGUAGE_DE,
      languageText: t("language." + LANGUAGE_DE),
      icon: <FlagDESVG/>
    },
    {
      language: LANGUAGE_EN,
      languageText: t("language." + LANGUAGE_EN),
      icon: <FlagENSVG/>
    }
  ], [language]);

  const menuItems: MainMenuItem[] = useMemo(() => {
    return [
      {
        path: LINK_REGISTRATION_AUTHORITIES_ADMINISTRATION,
        title: t("menu.registrationAuthoritiesAdministration"),
        anyPermissions: [PERMISSION.REGISTRATION_AUTHORITY_LIST]
      },
      {
        path: LINK_ANALYSIS,
        title: t("menu.analysis"),
        anyPermissions: [PERMISSION.REGISTRATION_AUTHORITY_LIST]
      },
      !!userMe?.registrationAuthorityAdmins?.length && {
        path: LINK_REGISTRATION_AUTHORITIES,
        title: t("menu.registrationAuthorities"),
        anyPermissions: []
      },
      (!!userMe?.institutionIssuers?.length || !!userMe?.institutionAdmins?.length) && {
        path: LINK_INSTITUTIONS,
        title: t("menu.institutions"),
        anyPermissions: []
      }
    ].filter((item) => !!item && (!item.anyPermissions?.length || hasAnyPermission(item.anyPermissions)));
  }, [language, permissions, userMe]);

  const addBreadcrumb = (breadcrumbs: any[], path: string, label: string, isLink: boolean) => {
    if (isLink) {
      breadcrumbs.push(<NavLink key={path} color="inherit" to={path}>{label}</NavLink>);
    } else {
      breadcrumbs.push(<Typography key={path}>{label}</Typography>);
    }
  };

  const handleAnalysisBreadcrumbs = (breadcrumbs: any[], path: string, part: string, index: number, isLink: boolean) => {
    if (index === 2) {
      addBreadcrumb(breadcrumbs, path, breadcrumbValues[part] ?? t("menu.registrationAuthority"), isLink);
    } else if (index === 3) {
      addBreadcrumb(breadcrumbs, path, breadcrumbValues[part] ?? t("menu.institution"), isLink);
    }
  };

  const handleRegistrationAuthorityAdministrationBreadcrumbs = (breadcrumbs: any[], path: string, part: string, index: number, isLink: boolean) => {
    if (index === 2) {
      addBreadcrumb(breadcrumbs, path, breadcrumbValues[part] ?? t("menu.registrationAuthority"), isLink);
    }
    if (index === 3) {
      if (path.endsWith(LINK_PART_ANALYSIS)) {
        addBreadcrumb(breadcrumbs, path, breadcrumbValues[part] ?? t("menu.analysis"), isLink);
      }
    }
  };

  const handleRegistrationAuthorityBreadcrumbs = (breadcrumbs: any[], path: string, part: string, index: number, isLink: boolean) => {
    if (index === 2) {
      const registrationAuthority = getMyRegistrationAuthority(part);
      addBreadcrumb(breadcrumbs, path, registrationAuthority?.registrationAuthority?.name, isLink);
    }
    if (index === 3) {
      if (path.endsWith(LINK_PART_IMPORT)) {
        addBreadcrumb(breadcrumbs, path, breadcrumbValues[part] ?? t("menu.importInstitutions"), isLink);
      } else if (path.endsWith(LINK_PART_ANALYSIS)) {
        addBreadcrumb(breadcrumbs, path, breadcrumbValues[part] ?? t("menu.analysis"), isLink);
      } else {
        addBreadcrumb(breadcrumbs, path, breadcrumbValues[part] ?? t("menu.institution"), isLink);
      }
    }

    if (index === 4) {
      if (path.endsWith(LINK_PART_ANALYSIS)) {
        addBreadcrumb(breadcrumbs, path, breadcrumbValues[part] ?? t("menu.analysis"), isLink);
      }
    }
  };

  const processMenuPartBreadcrumb = (breadcrumbs: any[], pathname: string, part: string, path: string, index: number, length: number, skipParent: boolean) => {
    if (skipParent && index === 1) {
      return;
    }
    const isLink = length !== (index + 1);
    if (pathname.includes(LINK_INSTITUTIONS) && index === 2) {
      const institution = getMyAdminInstitution(part) ?? getMyIssuerInstitution(part);
      addBreadcrumb(breadcrumbs, path, institution?.institution?.name, isLink);
    } else if (pathname.startsWith(LINK_ANALYSIS) && index > 1) {
      handleAnalysisBreadcrumbs(breadcrumbs, path, part, index, isLink);
    } else if (pathname.includes(LINK_REGISTRATION_AUTHORITIES_ADMINISTRATION) && index > 1) {
      handleRegistrationAuthorityAdministrationBreadcrumbs(breadcrumbs, path, part, index, isLink);
    } else if (pathname.includes(LINK_REGISTRATION_AUTHORITIES) && index > 1) {
      handleRegistrationAuthorityBreadcrumbs(breadcrumbs, path, part, index, isLink);
    } else {
      addBreadcrumb(breadcrumbs, path, t("menu." + part), isLink);
    }
  };

  const breadcrumbs: any[] = useMemo(() => {
    const {registrationAuthorityAdmins, institutionAdmins, institutionIssuers} = userMe ?? {};
    const isOnlyOneInstitution = (institutionAdmins?.length + institutionIssuers?.length) === 1;
    const notInstitutionAdmin = !institutionAdmins?.length;
    const isOnlyOneRegistrationAuthority = registrationAuthorityAdmins?.length === 1;
    const noInstitutions = !institutionAdmins?.length && !institutionIssuers?.length;
    const noRegistrationAuthorities = !registrationAuthorityAdmins?.length;

    if (!hasPermission(PERMISSION.REGISTRATION_AUTHORITY_LIST) && !registrationAuthorityAdmins?.length && ((isOnlyOneInstitution && notInstitutionAdmin) || noInstitutions)) {
      return null;
    }

    const skipParents = (isOnlyOneInstitution && noRegistrationAuthorities)
      || (isOnlyOneRegistrationAuthority && noInstitutions);

    const breadcrumbs: any[] = [];
    const parts = pathname.split("/");
    let path = "";
    parts.forEach((part, index) => {
      path += (path ? "/" : "") + part;
      if (!index) {
        breadcrumbs.push(<NavLink key={0} color="inherit" to="/"><HomeIcon/></NavLink>);
      } else if (part) {
        processMenuPartBreadcrumb(breadcrumbs, pathname, part, path, index, parts.length, skipParents);
      }
    });
    if (pathname === LINK_ROOT) {
      breadcrumbs.push(<Typography key={pathname}>{t("menu.startPage")}</Typography>);
    }
    return breadcrumbs;
  }, [language, pathname, userMe, breadcrumbValues]);

  const footerLinks: FooterLink[] = useMemo(() => {
    return [
      {
        text: t("links.contact"),
        href: FOOTER_LINKS_HOST + "/kontakt"
      },
      {
        text: t("links.imprint"),
        href: FOOTER_LINKS_HOST + "/impressum"
      },
      {
        text: t("links.privacyPolicy"),
        href: FOOTER_LINKS_HOST + "/datenschutz"
      },
      {
        text: t("links.termsAndConditions"),
        href: FOOTER_LINKS_HOST + "/benutzerhinweise"
      },
      {
        text: t("links.termsAndConditionsWallet"),
        href: FOOTER_LINKS_HOST + "/benutzerhinweise"
      },
      {
        text: t("links.accessibilityStatement"),
        href: FOOTER_LINKS_HOST + "/erklaerung-zur-barrierefreiheit"
      },
      {
        text: t("links.reportBarrier"),
        href: FOOTER_LINKS_HOST + "/barriere-melden"
      }
    ];
  }, [language]);

  const headerNavigation = useMemo(() => (
    <>
      {!userName &&
        <Button onClick={onUserClick} variant="contained" color="primary" className="desktop-only">
          {t("main.login")}
        </Button>
      }
      {!!userName &&
        <Button onClick={onUserClick} variant="contained" color="primary" className="desktop-only round-button">
          {userName[0]}
        </Button>
      }
      {!userName &&
        <IconButton onClick={onUserClick} color="primary" className="mobile-and-tablet">
          <PersonOutlinedIcon/>
        </IconButton>
      }
      {!!userName &&
        <Button onClick={onUserClick} variant="contained" color="primary" className="mobile-and-tablet round-button">
          {userName[0]}
        </Button>
      }
    </>
  ), [language, userName]);

  const cookiesLink = `${FOOTER_LINKS_HOST}/datenschutz`;

  const {KEYCLOAK_ACCOUNT_URL} = window.env?.keycloak ?? {};

  return (
    <LoadingOverlay loading={usersMeLoading}>
      <DefaultLayout
        showHeaderMenu={!!breadcrumbs?.length || menuItems?.length > 1}
        menuItems={menuItems?.length > 1 ? menuItems : []}
        headerNavigation={headerNavigation}
        copyrightText={t("main.copyright")}
        footerLinks={footerLinks}
        breadcrumbs={breadcrumbs}
        languageSwitch={<LanguageSwitcher languages={languages} language={language} changeLanguage={changeLanguage}/>}
      />
      <UserMenuPopover
        firstName={decodedToken?.given_name} lastName={decodedToken?.family_name}
        onLogout={handleLogout} popoverHook={useUserPopover} popoverSetter={setUserPopover}>
        {!!KEYCLOAK_ACCOUNT_URL && <div className="margin-bottom-m">
          <a href={KEYCLOAK_ACCOUNT_URL} target="_blank" rel="noreferrer" onClick={hidePopover}>
            {t("main.manageYourAccount")}
          </a>
        </div>}
      </UserMenuPopover>
      <CookieAcceptBanner>
        <div>{t("cookies.consentText")}</div>
        {t("cookies.consentTextPart1")}{" "}
        <a href={cookiesLink} target="_blank" rel="noreferrer">{t("cookies.consentTextLink")}</a>
        {t("cookies.consentTextPart2")}
      </CookieAcceptBanner>
    </LoadingOverlay>
  );
};

export default React.memo(Layout);
