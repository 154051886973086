import React, {FC, memo, useMemo} from "react";
import DateRange from "../Layout/Form/DateRange";
import moment from "moment/moment";
import {FORMAT_DATE_SERVER} from "../../constants/date";
import {Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Control} from "react-hook-form";

interface AnalysisDateRangeFilterProps {
  setDateRange: (startDate: string, endDate: string) => void;
  onChange: () => void;
  control: Control<any>;
  getValues: () => any;
  defaultValues: any;
}

const AnalysisDateRangeFilter: FC<AnalysisDateRangeFilterProps> = (
  {
    setDateRange,
    onChange,
    control,
    getValues,
    defaultValues
  }
) => {
  const {t, i18n: {language}} = useTranslation();

  const rangeOptions = useMemo(() => ([
    {
      label: t("analysis.range.allTime"),
      onClick: () => {
        setDateRange("1970-01-01", moment().format(FORMAT_DATE_SERVER));
      }
    },
    {
      label: t("analysis.range.previousYear"),
      onClick: () => {
        setDateRange(
          moment().subtract(1, "year").startOf("year").format(FORMAT_DATE_SERVER),
          moment().startOf("year").subtract(1, "day").format(FORMAT_DATE_SERVER)
        );
      }
    },
    {
      label: t("analysis.range.currentYear"),
      onClick: () => {
        setDateRange(moment().startOf("year").format(FORMAT_DATE_SERVER), moment().format(FORMAT_DATE_SERVER));
      }
    },
    {
      label: t("analysis.range.previousMonth"),
      onClick: () => {
        setDateRange(defaultValues.startDate, defaultValues.endDate);
      }
    },
    {
      label: t("analysis.range.currentMonth"),
      onClick: () => {
        setDateRange(moment().startOf("month").format(FORMAT_DATE_SERVER), moment().format(FORMAT_DATE_SERVER));
      }
    }
  ]), [language]);

  return (
    <div className="AnalysisDateRangeFilter date-range-column flex-auto flex-row flex-wrap flex-gap">
      <div>
        <DateRange
          control={control} maxDate={moment().format(FORMAT_DATE_SERVER)} minDate={"1970-01-01"} onChange={onChange}
          getValues={getValues} startRequired endRequired
          startDateLabel={t("analysis.startDate")}
          endDateLabel={t("analysis.endDate")}
        />
      </div>
      <div className="flex-auto flex-row flex-wrap flex-gap">
        {rangeOptions.map(({onClick, label}) => (
          <div key={label}><Button variant="text" onClick={onClick}>{label}</Button></div>
        ))}
      </div>
    </div>
  );
};

export default memo(AnalysisDateRangeFilter);
