import {getRequestHooks} from "@nbp/dnafe-material-ui/dist/hooks/globalStateHooks";
import {
  analysisByInstitutionGet,
  AnalysisByInstitutionGetArgs,
  analysisByRegistrationAuthorityGet,
  AnalysisByRegistrationAuthorityGetArgs, AnalysisSignatureCounterResponseDto,
  analysisSummaryByRegistrationAuthorityGet,
  AnalysisSummaryByRegistrationAuthorityGetArgs, analysisTotalGet, AnalysisTotalGetArgs,
  InstitutionAnalysisResponse,
  PageAnalysisResponse, SummaryResponse
} from "../api";

export const [getAnalysisPaged, useAnalysisPagedLoading, useAnalysisPaged] =
  getRequestHooks<PageAnalysisResponse, AnalysisByRegistrationAuthorityGetArgs>(analysisByRegistrationAuthorityGet);

export const [getAnalysisSummary, useAnalysisSummaryLoading, useAnalysisSummary] =
  getRequestHooks<SummaryResponse, AnalysisSummaryByRegistrationAuthorityGetArgs>(analysisSummaryByRegistrationAuthorityGet);

export const [getAnalysisByInstitution, useAnalysisByInstitutionLoading, useAnalysisByInstitution] =
  getRequestHooks<InstitutionAnalysisResponse[], AnalysisByInstitutionGetArgs>(analysisByInstitutionGet);

export const [getAnalysisTotal, useAnalysisTotalLoading, useAnalysisTotal] =
  getRequestHooks<AnalysisSignatureCounterResponseDto, AnalysisTotalGetArgs>(analysisTotalGet);
