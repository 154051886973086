import {DataPagination, LoadingOverlay, PageContent, ValueWithLabel} from "@nbp/dnafe-material-ui/dist/components";
import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import PageHeader from "../../components/Layout/PageHeader/PageHeader";
import {usePageTitle} from "../../hooks/usePageTitle";
import {useTranslation} from "react-i18next";
import {useEvent} from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import {
  getMyRegistrationAuthority,
  getRegistrationAuthoritiesIdNames,
  useRegistrationAuthoritiesIdNamesData,
  useRegistrationAuthoritiesIdNamesLoading
} from "../../hooks/registrationAuthority";
import {getBreadcrumbValues, setBreadcrumbValues} from "../../hooks/general";
import {
  getAnalysisPaged,
  getAnalysisSummary,
  useAnalysisPaged,
  useAnalysisPagedLoading,
  useAnalysisSummary,
  useAnalysisSummaryLoading
} from "../../hooks/analisys";
import {FORMAT_DATE_SERVER} from "../../constants/date";
import moment from "moment";
import {useForm} from "react-hook-form";
import {Alert} from "@mui/material";
import "./RegistrationAuthorityAnalysisPage.scss";
import {AnalysisResponse} from "../../api";
import {usePagedTable} from "@nbp/dnafe-material-ui/dist/hooks/usePagedTable";
import {PagedResponse} from "@nbp/dnafe-material-ui/dist/models/paged";
import DataTable, {DataTableColumn} from "@nbp/dnafe-material-ui/dist/components/DataTable/DataTable";
import SearchField from "../../components/SearchField/SearchField";
import {useUserMeData} from "../../hooks/user";
import AnalysisDateRangeFilter from "../../components/analysis/AnalysisDateRangeFilter";
import {LINK_ANALYSIS, LINK_PART_ANALYSIS, LINK_REGISTRATION_AUTHORITIES} from "../../constants/navigate";
import {CERTIFICATE_ANY, CERTIFICATE_TRUSTED} from "../../constants/analysis";
import CertificateTypeSelect from "../../components/analysis/CertificateTypeSelect";

const getDefaultValues = () => ({
  startDate: moment().subtract(1, "month").startOf("month").format(FORMAT_DATE_SERVER),
  endDate: moment().startOf("month").subtract(1, "day").format(FORMAT_DATE_SERVER),
  certificateTypes: CERTIFICATE_TRUSTED
});

interface RegistrationAuthorityAnalysisPageRef {
  registrationAuthorityLoaded?: boolean;
  dataLoaded?: boolean;
}

const RegistrationAuthorityAnalysisPage = () => {
  usePageTitle("menu.analysis");
  const {registrationAuthorityId} = useParams();
  const {t, i18n: {language}} = useTranslation();
  const navigate = useNavigate();

  const registrationAuthoritiesIdNamesLoading = useRegistrationAuthoritiesIdNamesLoading();
  const registrationAuthoritiesIdNamesData = useRegistrationAuthoritiesIdNamesData();
  const userMe = useUserMeData();
  const registrationAuthority = useMemo(() => getMyRegistrationAuthority(registrationAuthorityId), [registrationAuthorityId, userMe]);
  const name = (registrationAuthority ? registrationAuthority.registrationAuthority?.name : registrationAuthoritiesIdNamesData?.registrationAuthorityName) ?? "";
  const defaultValues = getDefaultValues();
  const analysisPagedLoading = useAnalysisPagedLoading();
  const analysisSummaryLoading = useAnalysisSummaryLoading();
  const analysisSummary = useAnalysisSummary();
  const [error, setError] = useState(false);
  const ref = useRef<RegistrationAuthorityAnalysisPageRef>({});

  const {control, getValues, setValue, trigger} = useForm({defaultValues});

  const loader = useEvent(async () => {
    setError(false);
    try {
      const valid = await trigger().catch(console.error);
      if (valid) {
        const {startDate, endDate, certificateTypes} = getValues();
        const certificateType = certificateTypes === CERTIFICATE_ANY ? "" : certificateTypes;
        await getAnalysisPaged({
          startDate,
          endDate,
          search,
          sort: sortValue ? [sortValue] : undefined,
          registrationAuthorityId,
          certificateTypes: certificateType,
          page,
          size: pageSize
        }).catch(console.error);
        return await getAnalysisSummary({
          startDate,
          endDate,
          registrationAuthorityId,
          certificateTypes: certificateType
        }).catch(console.error);
      }
    } catch (error) {
      console.error(error);
    }
    setError(true);
    return null;
  });

  const {page, setPage, pageSize, search, setSearch, pages, tableRows, sort, onSort} =
    usePagedTable<AnalysisResponse>({
      useData: useAnalysisPaged as () => PagedResponse<AnalysisResponse>,
      useLoading: useAnalysisPagedLoading,
      loader,
      defaultSort: {field: "name", desc: false}
    });

  const sortValue = sort?.field ? [sort?.field, sort?.desc ? "desc" : "asc"].join(",") : undefined;

  const setDateRange = useEvent((startDate: string, endDate: string) => {
    setValue("startDate", startDate);
    setValue("endDate", endDate);
    loader();
  });

  const onRowClick = useEvent(({registrationAuthorityId, institution: {id}}: AnalysisResponse) => {
    if (registrationAuthority?.registrationAuthority.id) {
      navigate(LINK_REGISTRATION_AUTHORITIES + "/" + registrationAuthorityId + "/" + id + LINK_PART_ANALYSIS);
    } else {
      navigate(LINK_ANALYSIS + "/" + registrationAuthorityId + "/" + id);
    }
  });

  useEffect(() => {
    if (registrationAuthorityId && !ref.current.dataLoaded) {
      loader();
      ref.current.dataLoaded = true;
    }
  }, [registrationAuthorityId]);

  useEffect(() => {
    if (registrationAuthorityId && !ref.current.registrationAuthorityLoaded) {
      if (userMe && !registrationAuthority?.registrationAuthority) {
        getRegistrationAuthoritiesIdNames({id: registrationAuthorityId}).then(data => {
          if (data?.registrationAuthorityName) {
            setBreadcrumbValues({...getBreadcrumbValues(), [registrationAuthorityId]: data?.registrationAuthorityName});
          }
        }).catch(console.error);
        ref.current.registrationAuthorityLoaded = true;
      }
    }
  }, [registrationAuthorityId, userMe]);

  const columns: DataTableColumn<AnalysisResponse>[] = useMemo(() => [
    {
      name: "institution",
      title: t("analysis.institution"),
      sortable: true,
      sortField: "name",
      template: ({institution: {name}}) => name
    },
    {
      name: "numberOfSignatures",
      headerClassName: "number-column-header",
      title: t("analysis.numberOfSignatures")
    },
    {
      name: "numberOfRevocations",
      headerClassName: "number-column-header",
      title: t("analysis.numberOfRevocations")
    }
  ], [language]);

  const loading = registrationAuthoritiesIdNamesLoading || analysisPagedLoading || analysisSummaryLoading;

  return (
    <LoadingOverlay className="RegistrationAuthorityAnalysisPage" loading={loading}>
      <PageHeader title={name ?? t("menu.registrationAuthorityAdministration")} subTitleMuted={t("menu.analysis")}>
        <CertificateTypeSelect control={control} onChange={loader}/>
      </PageHeader>
      <PageContent>
        <div className="filter-row flex-row flex-wrap flex-gap margin-bottom-m">
          <AnalysisDateRangeFilter
            setDateRange={setDateRange} control={control} defaultValues={defaultValues} getValues={getValues}
            onChange={loader}
          />
        </div>
        {error && <Alert className="margin-bottom-m" color="error">
          {t("analysis.filterInvalid")}
        </Alert>}
        <div className="content-block">
          <div className="flex-row flex-gap flex-wrap flex-columns-3">
            <div className="flex-1">
              <ValueWithLabel
                label={t("analysis.numberOfSignatures")} value={String(analysisSummary?.numberOfSignatures ?? "")}
                noEmptyText={analysisSummaryLoading}
              />
            </div>
            <div className="flex-1">
              <ValueWithLabel
                label={t("analysis.numberOfRevocations")} value={String(analysisSummary?.numberOfRevocations ?? "")}
                noEmptyText={analysisSummaryLoading}
              />
            </div>
          </div>
        </div>
      </PageContent>
      <PageHeader subTitle={t("analysis.institutions")}>
        <SearchField placeholder={t("analysis.search")} onSearch={setSearch} className="margin-bottom-s"/>
      </PageHeader>
      <PageContent>
        <DataTable
          data={tableRows} columns={columns} emptyText={t("main.empty")} sort={sort} onSort={onSort}
          onRowClick={onRowClick}
        />
        <DataPagination pages={pages || 0} onChange={setPage} value={page}/>
      </PageContent>
    </LoadingOverlay>
  );
};

export default memo(RegistrationAuthorityAnalysisPage);
