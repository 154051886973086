import {
  DataPagination,
  DataTable,
  DataTableActionsColumn,
  LoadingOverlay,
  PageContent
} from "@nbp/dnafe-material-ui/dist/components";
import React, {memo, useCallback, useEffect, useMemo, useRef} from "react";
import {useNavigate, useParams} from "react-router-dom";
import PageHeader from "../components/Layout/PageHeader/PageHeader";
import {usePageTitle} from "../hooks/usePageTitle";
import {useTranslation} from "react-i18next";
import {useEvent} from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import {RegistrationAuthorityAdminResponse, RegistrationAuthorityResponse} from "../api";
import {
  deleteRegistrationAuthorityAdmin,
  getRegistrationAuthority,
  getRegistrationAuthorityAdmins,
  setRegistrationAuthorityActionsPopover,
  setRegistrationAuthorityAdminsLoadedFor,
  setRegistrationAuthorityMenuPopover,
  useDeleteRegistrationAuthorityAdminLoading,
  useGetRegistrationAuthorityData,
  useGetRegistrationAuthorityLoading,
  useRegistrationAuthorityAdmins,
  useRegistrationAuthorityAdminsLoadedFor,
  useRegistrationAuthorityAdminsLoading
} from "../hooks/registrationAuthority";
import moment from "moment";
import {FORMAT_DATE_TIME_CLIENT, FORMAT_DATE_TIME_SERVER} from "../constants/date";
import {useModal} from "mui-modal-provider";
import {usePagedTable} from "@nbp/dnafe-material-ui/dist/hooks/usePagedTable";
import {SimpleDialogProps} from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import {DataTableColumn} from "@nbp/dnafe-material-ui/dist/components/DataTable/DataTable";
import RegistrationAuthorityAdminCreateDialog
  from "../components/registrationAuthority/RegistrationAuthorityAdminCreateDialog";
import {Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RegistrationAuthorityAdminMenuPopover
  from "../components/registrationAuthority/RegistrationAuthorityAdminMenuPopover";
import {hasPermission} from "../hooks/auth";
import {PERMISSION} from "../constants/permission";
import {getBreadcrumbValues, setBreadcrumbValues} from "../hooks/general";
import {getUserMe, useUserMeData} from "../hooks/user";
import {LINK_ANALYSIS} from "../constants/navigate";
import {showError, showSuccess} from "@nbp/dnafe-material-ui/dist/hooks/snackbar";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  RegistrationAuthorityActionsMenuPopover
} from "../components/registrationAuthority/RegistrationAuthorityActionsMenuPopover";

const RegistrationAuthorityAdministrationDetailsPage = () => {
  usePageTitle("menu.registrationAuthority");
  const {registrationAuthorityId} = useParams();
  const {t, i18n: {language}} = useTranslation();
  const registrationAuthorityAdminsLoading = useRegistrationAuthorityAdminsLoading();
  const deleteRegistrationAuthorityAdminLoading = useDeleteRegistrationAuthorityAdminLoading();
  const registrationAuthorityLoading = useGetRegistrationAuthorityLoading();
  const registrationAuthorityData = useGetRegistrationAuthorityData();
  const registrationAuthorityAdminsLoadedFor = useRegistrationAuthorityAdminsLoadedFor();
  const {showModal} = useModal();
  const userMe = useUserMeData();
  const ref = useRef<string>(null);
  const navigate = useNavigate();

  const loader = useEvent(async () => {
    if (hasPermission(PERMISSION.REGISTRATION_AUTHORITY_ADMIN_LIST)) {
      await getRegistrationAuthorityAdmins({
        size: pageSize, page, sort: sortValue ? [sortValue] : [], registrationAuthorityId
      }).then(() => {
        setRegistrationAuthorityAdminsLoadedFor(registrationAuthorityId);
      }).catch(console.error);
    }
  });

  const {page, setPage, pageSize, pages, tableRows, sort, onSort} =
    usePagedTable<RegistrationAuthorityAdminResponse>({
      useData: useRegistrationAuthorityAdmins,
      useLoading: useRegistrationAuthorityAdminsLoading,
      loader
    });

  const tableData = registrationAuthorityAdminsLoadedFor === registrationAuthorityId ? tableRows : [];
  const sortValue = sort?.field ? [sort?.field, sort?.desc ? "desc" : "asc"].join(",") : undefined;

  const showAddRegistrationAuthorityAdminDialog = useEvent(() => showModal((props: SimpleDialogProps) => (
    <RegistrationAuthorityAdminCreateDialog registrationAuthorityId={registrationAuthorityId} {...props}
                                            onUpdate={loader}/>
  )));

  useEffect(() => {
    if (registrationAuthorityId) {
      if (ref.current !== registrationAuthorityId) {
        loader();
        getRegistrationAuthority({id: registrationAuthorityId}).then(data => {
          if (data?.id) {
            setBreadcrumbValues({...getBreadcrumbValues(), [data.id]: data.name});
          }
        }).catch(console.error);
      }
      ref.current = registrationAuthorityId;
    }
  }, [registrationAuthorityId]);

  const deleteHandler = useEvent(async (id: string) => await deleteRegistrationAuthorityAdmin({
    id,
    registrationAuthorityId
  }).then(() => {
    showSuccess(t("registrationAuthority.admin.deleteSuccess"));
    loader();

    if (userMe.registrationAuthorityAdmins.some(item => item.id === id)) {
      getUserMe().catch(console.error);
    }
  }).catch(error => showError(error.message)));

  const DataTableActionsColumnTemplate = useCallback((row: RegistrationAuthorityResponse) => (
    <DataTableActionsColumn row={row} openMenuPopover={setRegistrationAuthorityMenuPopover}/>
  ), [language]);

  const columns: DataTableColumn<RegistrationAuthorityResponse>[] = useMemo(() => [
    {
      name: "email",
      title: t("registrationAuthority.admin.email"),
      sortable: true
    },
    {
      name: "createdAt",
      headerClassName: "column-created-at",
      title: t("main.createdAt"),
      sortable: true,
      template: ({createdAt}: RegistrationAuthorityResponse) =>
        moment(createdAt, FORMAT_DATE_TIME_SERVER).format(FORMAT_DATE_TIME_CLIENT)
    },
    hasPermission(PERMISSION.REGISTRATION_AUTHORITY_ADMIN_DELETE) && {
      name: "actions",
      className: "action-column",
      headerClassName: "action-column-header",
      title: t("main.actions"),
      template: DataTableActionsColumnTemplate
    }
  ].filter(item => !!item), [language]);

  const showActionsMenu = useEvent((event: Event) => setRegistrationAuthorityActionsPopover({anchor: event.currentTarget}));

  const openAnalysis = useEvent(() => {
    navigate(LINK_ANALYSIS + "/" + registrationAuthorityId);
  });

  const {name} = registrationAuthorityData ?? {};
  const loading = registrationAuthorityAdminsLoading || deleteRegistrationAuthorityAdminLoading || registrationAuthorityLoading;

  return (
    <LoadingOverlay className="RegistrationAuthorityAdministrationDetailsPage" loading={loading}>
      <PageHeader
        title={name ?? t("menu.registrationAuthorityAdministration")}
        subTitleMuted={t("menu.registrationAuthorityAdministration")}
      >
        <div className="flex-row flex-wrap flex-gap">
          <Button
            endIcon={<KeyboardArrowDownOutlinedIcon/>} onClick={showActionsMenu} variant="outlined" color="primary">
            {t("main.actions")}
          </Button>
          <RegistrationAuthorityActionsMenuPopover onOpenAnalyse={openAnalysis}/>
        </div>
      </PageHeader>
      <PageHeader subTitle={t("registrationAuthority.admin.title")}>
        {hasPermission(PERMISSION.REGISTRATION_AUTHORITY_ADMIN_CREATE) &&
          <Button onClick={showAddRegistrationAuthorityAdminDialog} variant="outlined" color="primary"
                  startIcon={<AddIcon/>}>
            {t("registrationAuthority.addAdmin")}
          </Button>
        }
      </PageHeader>
      <PageContent>
        {hasPermission(PERMISSION.REGISTRATION_AUTHORITY_ADMIN_LIST) && <>
          <DataTable
            data={tableData} columns={columns} sort={sort} onSort={onSort}
            emptyText={t("main.empty")}
          />
          {!!pages && <DataPagination pages={pages} onChange={setPage} value={page}/>}
        </>}
      </PageContent>
      <RegistrationAuthorityAdminMenuPopover onDelete={deleteHandler}/>
    </LoadingOverlay>
  );
};

export default memo(RegistrationAuthorityAdministrationDetailsPage);
