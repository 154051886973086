import React, {FC, memo} from "react";
import {FormControl, IconButton, TextField} from "@mui/material";
import {FileCopy} from "@mui/icons-material";
import "./FieldWithCopy.scss";
import {useEvent} from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import {useTranslation} from "react-i18next";
import {showSuccess} from "@nbp/dnafe-material-ui/dist/hooks/snackbar";

interface FieldWithCopyProps {
  label?: string;
  value?: string;
  fullWidth?: boolean;
  multiline?: boolean
}

const FieldWithCopy: FC<FieldWithCopyProps> = ({label, value, fullWidth, multiline}) => {
  const {t} = useTranslation();

  const copy = useEvent(() => {
    void navigator.clipboard.writeText(value);
    showSuccess(t("main.valueCopied"));
  });

  return (
    <FormControl fullWidth className="FieldWithCopy FormField read-only">
      <TextField
        label={label} value={value} fullWidth={fullWidth} multiline={multiline}
        InputProps={{
          readOnly: true,
          endAdornment: <div className="end-adornment">
            <IconButton size="small" aria-label="copy" onClick={copy}>
              <FileCopy/>
            </IconButton>
          </div>
        }}
      />
    </FormControl>
  );
};

export default memo(FieldWithCopy);
