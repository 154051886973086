import {ConfirmationDialog, LoadingOverlay, PageContent, ValueWithLabel} from "@nbp/dnafe-material-ui/dist/components";
import React, {memo, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import PageHeader from "../components/Layout/PageHeader/PageHeader";
import {Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {usePageTitle} from "../hooks/usePageTitle";
import {useTranslation} from "react-i18next";
import InstitutionDetailsDialog from "../components/Institution/InstitutionDetailsDialog";
import {useEvent} from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import {useModal} from "mui-modal-provider";
import {SimpleDialogProps} from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import {
  approveInstitutionAdmin,
  approveInstitutionIssuer,
  createInstitutionAdmin,
  createInstitutionIssuer,
  deleteInstitutionAdmin,
  deleteInstitutionIssuer,
  getInstitution,
  hasInstitutionAdminAccess,
  setInstitutionActionsPopover,
  setInstitutionAddressMenuPopover,
  setInstitutionAdminMenuPopover,
  setInstitutionIssuerMenuPopover,
  updateInstitution,
  updateInstitutionOperatingStatus,
  useApproveInstitutionAdminLoading,
  useApproveInstitutionIssuerLoading,
  useDeleteInstitutionAdminLoading,
  useDeleteInstitutionIssuerLoading,
  useInstitutionAddressMenuPopover,
  useInstitutionAdminLoading,
  useInstitutionAdminMenuPopover,
  useInstitutionIssuerLoading,
  useInstitutionIssuerMenuPopover,
  useInstitutionLoading,
  useUpdateInstitutionLoading
} from "../hooks/institution";
import InstitutionMembersTable from "../components/Institution/InstitutionMembersTable";
import InstitutionMemberCreateDialog from "../components/Institution/InstitutionMemberCreateDialog";
import {
  AddressRequest,
  InstitutionBaseResponse,
  InstitutionDetailedResponse,
  InstitutionRequestClosingReason
} from "../api";
import {hasRegistrationAuthorityAccess} from "../hooks/registrationAuthority";
import {getBreadcrumbValues, setBreadcrumbValues, useAxiosErrorHandler} from "../hooks/general";
import {getInstitutionAddress, getInstitutionDataValues} from "../helpers/institution";
import {FORMAT_DATE_CLIENT} from "../constants/date";
import moment from "moment";
import InstitutionOperatingStatus from "../components/Institution/InstitutionOperatingStatus";
import {
  ADDRESS_ROLE_DEPENDENCY,
  ADDRESS_ROLE_MAIN,
  CLOSING_REASON_NONE,
  INSTITUTION_STATUS_CLOSED,
  INSTITUTION_STATUS_CLOSED_FOREVER,
  INSTITUTION_STATUS_OPEN
} from "../constants/institution";
import {useForm} from "react-hook-form";
import InstitutionCloseConfirmationDialog
  from "../components/Institution/InstitutionCloseConfirmationDialog/InstitutionCloseConfirmationDialog";
import InstitutionMergeDialog from "../components/Institution/InstitutionMergeDialog";
import {LINK_INSTITUTIONS, LINK_PART_ANALYSIS, LINK_REGISTRATION_AUTHORITIES} from "../constants/navigate";
import {InstitutionActionsMenuPopover} from "../components/Institution/InstitutionActionsMenuPopover";
import InstitutionSplitDialog from "../components/Institution/InstitutionSplitDialog";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {showError, showSuccess} from "@nbp/dnafe-material-ui/dist/hooks/snackbar";
import InstitutionAddressesTable from "../components/Institution/InstitutionAddressesTable";
import InstitutionAddressDialog from "../components/Institution/InstitutionAddressDialog";
import {InstitutionRequestData} from "../models/institution";
import InstitutionMTLSAccessDialog from "../components/Institution/InstitutionMTLSAccessDialog";

const InstitutionDetailsPage = () => {
  usePageTitle("menu.institution");
  const {institutionId} = useParams();
  const {t} = useTranslation();
  const {showModal} = useModal();
  const navigate = useNavigate();
  const institutionLoading = useInstitutionLoading();
  const updateInstitutionLoading = useUpdateInstitutionLoading();
  const deleteInstitutionAdminLoading = useDeleteInstitutionAdminLoading();
  const approveInstitutionAdminLoading = useApproveInstitutionAdminLoading();
  const deleteInstitutionIssuerLoading = useDeleteInstitutionIssuerLoading();
  const approveInstitutionIssuerLoading = useApproveInstitutionIssuerLoading();
  const [data, setData] = useState<InstitutionDetailedResponse>(null);
  const axiosErrorHandler = useAxiosErrorHandler();
  const ref = useRef<string>(null);

  const loader = useEvent(() => {
    getInstitution({id: institutionId}).then(data => {
      setData(data);
      if (data?.id) {
        setBreadcrumbValues({...getBreadcrumbValues(), [data.id]: data.name});
      }
    }).catch(console.error);
  });

  const {control, getValues, setValue} = useForm({
    defaultValues: {closingReason: CLOSING_REASON_NONE}
  });

  const onAdminDelete = useEvent((memberId: string) => {
    deleteInstitutionAdmin({institutionId, memberId}).then(() => {
      showSuccess(t("institution.member.deleteSuccess"));
      loader();
    }).catch(console.error);
  });

  const onAdminApprove = useEvent((memberId: string) => {
    approveInstitutionAdmin({
      args: {institutionId, memberId},
      body: {result: "APPROVED", reason: ""}
    }).then(() => {
      showSuccess(t("institution.member.approveSuccess"));
      loader();
    }).catch(console.error);
  });

  const onIssuerDelete = useEvent((memberId: string) => {
    deleteInstitutionIssuer({institutionId, memberId}).then(() => {
      showSuccess(t("institution.member.deleteSuccess"));
      loader();
    }).catch(console.error);
  });

  const onAddressDelete = useEvent(async (address: AddressRequest) => {
    const body = new InstitutionRequestData(getInstitutionDataValues(data));
    await updateInstitution({
      id: data.id,
      body: {
        ...body,
        addresses: addresses.filter(item => {
          return item.id !== address.id;
        })
      }
    }).then(() => {
      showSuccess(t("institution.addresses.deleteSuccess"));
      loader();
    }).catch(axiosErrorHandler);
  });

  const onIssuerApprove = useEvent((memberId: string) => {
    approveInstitutionIssuer({
      args: {institutionId, memberId},
      body: {result: "APPROVED", reason: ""}
    }).then(() => {
      showSuccess(t("institution.member.approveSuccess"));
      loader();
    }).catch(console.error);
  });

  const createInstitutionAddress = useEvent((address: AddressRequest) => {
    const body = new InstitutionRequestData(getInstitutionDataValues(data));
    return updateInstitution({
      id: data.id,
      body: {
        ...body,
        addresses: [...data.addresses, {
          ...address,
          roleOfAddress: ADDRESS_ROLE_DEPENDENCY
        }]
      }
    });
  });

  const updateInstitutionAddress = useEvent((address: AddressRequest) => {
    const body = new InstitutionRequestData(getInstitutionDataValues(data));
    const addresses = data.addresses.map(item => {
      if (item.id === address.id) {
        return {...item, ...address};
      } else {
        return item;
      }
    });

    return updateInstitution({id: data.id, body: {...body, addresses}});
  });

  useEffect(() => {
    if (institutionId) {
      if (ref.current !== institutionId) {
        loader();
      }
      ref.current = institutionId;
    }
  }, [institutionId]);

  const showEditInstitutionDialog = useEvent(() => showModal((props: SimpleDialogProps) => (
    <InstitutionDetailsDialog
      {...props} onUpdate={loader} data={data} registrationAuthorityId={data?.registrationAuthority?.id}/>
  )));

  const showInstitutionAdminCreateDialog = useEvent(() => showModal((props: SimpleDialogProps) => (
    <InstitutionMemberCreateDialog
      {...props} onUpdate={loader} institutionId={institutionId} title={t("institution.member.createAdminTitle")}
      saveHandler={createInstitutionAdmin} useLoading={useInstitutionAdminLoading}/>
  )));

  const showInstitutionIssuerCreateDialog = useEvent(() => showModal((props: SimpleDialogProps) => (
    <InstitutionMemberCreateDialog
      {...props} onUpdate={loader} institutionId={institutionId} title={t("institution.member.createIssuerTitle")}
      saveHandler={createInstitutionIssuer} useLoading={useInstitutionIssuerLoading}/>
  )));

  const showInstitutionAddressCreateDialog = useEvent(() => showModal((props: SimpleDialogProps) => (
    <InstitutionAddressDialog
      {...props} onUpdate={loader} title={t("institution.addresses.createAddressTitle")}
      saveHandler={createInstitutionAddress} useLoading={useUpdateInstitutionLoading}/>
  )));

  const showInstitutionAddressEditDialog = useEvent((address: AddressRequest) => showModal((props: SimpleDialogProps) => (
    <InstitutionAddressDialog
      {...props} onUpdate={loader} title={t("institution.addresses.createAddressTitle")} data={address}
      saveHandler={updateInstitutionAddress} useLoading={useUpdateInstitutionLoading}/>
  )));

  const updateOperatingStatus = useEvent(async (operatingStatus: "OPEN" | "CLOSED") =>
    await updateInstitutionOperatingStatus({
      args: {institutionId},
      body: {operatingStatus, closingReason: getValues()?.closingReason as InstitutionRequestClosingReason}
    }).then(() => {
      showSuccess(t("institutions.dialog.saveSuccess"));
      loader();
    }).catch(error => showError(error.message)));

  const showOpenConfirmation = useEvent(() => showModal((props: SimpleDialogProps) => (
    <ConfirmationDialog
      text={t("institutions.openConfirmation")} confirmText={t("institutions.openInstitution")}
      title={t("main.confirmation")} cancelText={t("main.cancel")}
      onConfirm={() => updateOperatingStatus(INSTITUTION_STATUS_OPEN)} {...props}
    />
  )));

  const showCloseConfirmation = useEvent(() => {
    setValue("closingReason", CLOSING_REASON_NONE);
    showModal((props: SimpleDialogProps) => (<InstitutionCloseConfirmationDialog
      control={control} onConfirm={() => updateOperatingStatus(INSTITUTION_STATUS_CLOSED)} {...props}
    />));
  });

  const showPermanentlyCloseConfirmation = useEvent(() => {
    showModal((props: SimpleDialogProps) => (<ConfirmationDialog
      text={t("institutions.permanentlyCloseConfirmation")} confirmText={t("institutions.permanentlyClose")}
      title={t("main.confirmation")} cancelText={t("main.cancel")}
      onConfirm={() => updateOperatingStatus(INSTITUTION_STATUS_CLOSED_FOREVER)} {...props}
    />));
  });

  const onMergeToNew = useEvent((institution: InstitutionBaseResponse) => {
    navigate(LINK_REGISTRATION_AUTHORITIES + "/" + data?.registrationAuthority?.id + "/" + institution?.id);
  });

  const onOpenAnalysis = useEvent(() => {
    if (isRegistrationAuthority) {
      navigate(LINK_REGISTRATION_AUTHORITIES + "/" + data?.registrationAuthority?.id + "/" + institutionId + LINK_PART_ANALYSIS);
    } else if (isInstitutionAdmin) {
      navigate(LINK_INSTITUTIONS + "/" + institutionId + LINK_PART_ANALYSIS);
    }
  });

  const showMergeDialog = useEvent(() => showModal((props: SimpleDialogProps) => (
    <InstitutionMergeDialog {...props} data={data} onMerge={loader} onMergeToNew={onMergeToNew}/>
  )));

  const showSetupMTLSDialog = useEvent(() => showModal((props: SimpleDialogProps) => (
    <InstitutionMTLSAccessDialog {...props} institutionId={institutionId}/>
  )));

  const showSplitDialog = useEvent(() => showModal((props: SimpleDialogProps) => (
    <InstitutionSplitDialog {...props} data={data} onSplit={loader}/>
  )));

  const showActionsMenu = useEvent((event: Event) => setInstitutionActionsPopover({anchor: event.currentTarget}));

  const {
    name,
    type,
    admins,
    issuers,
    addresses,
    openingDate,
    closingDate,
    operatingStatus,
    closingReason,
    registrationAuthority
  } = data ?? {};

  const registrationAuthorityName = registrationAuthority?.name;
  const addressValue = getInstitutionAddress(data ?? {}, t);
  const dependencies = (addresses ?? []).filter(item => item.roleOfAddress === ADDRESS_ROLE_DEPENDENCY);
  const {phone, fax, email, website} = addresses?.find(item => item.roleOfAddress === ADDRESS_ROLE_MAIN) ?? {};

  const loading = institutionLoading || deleteInstitutionAdminLoading || deleteInstitutionIssuerLoading
    || approveInstitutionAdminLoading || approveInstitutionIssuerLoading || updateInstitutionLoading;

  const isRegistrationAuthority = hasRegistrationAuthorityAccess(data?.registrationAuthority?.id);
  const isInstitutionAdmin = hasInstitutionAdminAccess(institutionId);
  const isClosedForever = operatingStatus === INSTITUTION_STATUS_CLOSED_FOREVER;

  return (
    <LoadingOverlay className="InstitutionDetailsPage" loading={loading}>
      <PageHeader
        title={<div className="flex-row flex-gap flex-wrap">
          <div>{name ?? t("institution.title")}</div>
          <div>{!!operatingStatus && <InstitutionOperatingStatus operatingStatus={operatingStatus}/>}</div>
        </div>}
        subTitleMuted={t("institution.title")}
      >
        <div className="flex-row flex-wrap flex-gap">
          {(isRegistrationAuthority || isInstitutionAdmin) && <Button
            endIcon={<KeyboardArrowDownOutlinedIcon/>} onClick={showActionsMenu} variant="outlined" color="primary"
            disabled={isClosedForever}>
            {t("main.actions")}
          </Button>}
          <InstitutionActionsMenuPopover
            isRegistrationAuthority={isRegistrationAuthority} isInstitutionAdmin={isInstitutionAdmin}
            onClose={showCloseConfirmation} onOpen={showOpenConfirmation} onOpenAnalysis={onOpenAnalysis}
            onEdit={showEditInstitutionDialog} onMerge={showMergeDialog} onSetupMTLS={showSetupMTLSDialog}
            operatingStatus={operatingStatus} onSplit={showSplitDialog}
            onPermanentlyClose={showPermanentlyCloseConfirmation}
          />
        </div>
      </PageHeader>
      <PageContent>
        <div className="content-block">
          <div className="flex-row flex-gap flex-wrap flex-columns-3">
            <div className="flex-1">
              <ValueWithLabel label={t("institution.registrationAuthorityName")} value={registrationAuthorityName ?? ""}
                              noEmptyText={loading}/>
            </div>
          </div>
          <div className="flex-row flex-gap flex-wrap flex-columns-3">
            <div className="flex-1">
              <ValueWithLabel label={t("institution.type")} value={type ?? ""} noEmptyText={loading}/>
            </div>
            <div className="flex-1">
              <ValueWithLabel label={t("institution.address")} value={addressValue ?? ""} noEmptyText={loading}/>
            </div>
          </div>
          <div className="flex-row flex-gap flex-wrap flex-columns-3">
            <div className="flex-1">
              <ValueWithLabel label={t("institution.addresses.phone")} noEmptyText={loading} value={phone}/>
            </div>
            <div className="flex-1">
              <ValueWithLabel label={t("institution.addresses.fax")} noEmptyText={loading} value={fax}/>
            </div>
          </div>
          <div className="flex-row flex-gap flex-wrap flex-columns-3">
            <div className="flex-1">
              <ValueWithLabel label={t("institution.addresses.email")} noEmptyText={loading} value={email}/>
            </div>
            <div className="flex-1">
              <ValueWithLabel label={t("institution.addresses.website")} noEmptyText={loading} value={website}/>
            </div>
          </div>
          <div className="flex-row flex-gap flex-wrap flex-columns-3">
            <div className="flex-1">
              <ValueWithLabel
                label={t("institution.openingDate")} noEmptyText={loading}
                value={openingDate ? moment(openingDate).format(FORMAT_DATE_CLIENT) : ""}
              />
            </div>
            <div className="flex-1">
              <ValueWithLabel
                label={t("institution.closingDate")} noEmptyText={loading}
                value={closingDate ? moment(closingDate).format(FORMAT_DATE_CLIENT) : ""}
              />
            </div>
          </div>
          {!!closingDate && <div className="margin-bottom-s">
            <ValueWithLabel
              label={t("institution.closingReason")} noEmptyText={loading}
              value={t("institution.reason." + closingReason)}
            />
          </div>}
        </div>
        {(isInstitutionAdmin || isRegistrationAuthority) && <>
          <div className="flex-row flex-gap flex-wrap margin-bottom-l">
            <div className="flex-auto flex-column-justify-center">
              <h3 className="text-title-2 margin-bottom-0">{t("institution.member.adminsTitle")}</h3>
            </div>
            {isRegistrationAuthority && !isClosedForever &&
              <div>
                <Button startIcon={<AddIcon/>} onClick={showInstitutionAdminCreateDialog} variant="outlined"
                        color="primary">
                  {t("institution.member.addAdminButton")}
                </Button>
              </div>
            }
          </div>
          <InstitutionMembersTable
            data={admins} onDelete={onAdminDelete} onApprove={onAdminApprove}
            readOnly={!isRegistrationAuthority || isClosedForever}
            setPopover={setInstitutionAdminMenuPopover} usePopover={useInstitutionAdminMenuPopover}/>
        </>}
        {(isInstitutionAdmin || isRegistrationAuthority) && <>
          <div className="flex-row flex-gap flex-wrap margin-bottom-l margin-top-l">
            <div className="flex-auto flex-column-justify-center">
              <h3 className="text-title-2 margin-bottom-0">{t("institution.member.issuersTitle")}</h3>
            </div>
            {isInstitutionAdmin && !isClosedForever &&
              <div>
                <Button onClick={showInstitutionIssuerCreateDialog} variant="outlined" color="primary"
                        startIcon={<AddIcon/>}>
                  {t("institution.member.addIssuerButton")}
                </Button>
              </div>
            }
          </div>
          <InstitutionMembersTable
            data={issuers} onDelete={onIssuerDelete} onApprove={onIssuerApprove}
            readOnly={!isInstitutionAdmin || isClosedForever}
            setPopover={setInstitutionIssuerMenuPopover} usePopover={useInstitutionIssuerMenuPopover}
          />
        </>}
        <div className="flex-row flex-gap flex-wrap margin-bottom-l margin-top-l">
          <div className="flex-auto flex-column-justify-center">
            <h3 className="text-title-2 margin-bottom-0">{t("institution.addresses.addressesTitle")}</h3>
          </div>
          {isRegistrationAuthority && !isClosedForever &&
            <div>
              <Button onClick={showInstitutionAddressCreateDialog} variant="outlined" color="primary"
                      startIcon={<AddIcon/>}>
                {t("institution.addresses.addAddressButton")}
              </Button>
            </div>
          }
        </div>
        <InstitutionAddressesTable
          data={dependencies} onDelete={onAddressDelete} onEdit={showInstitutionAddressEditDialog}
          usePopover={useInstitutionAddressMenuPopover} setPopover={setInstitutionAddressMenuPopover}
          readOnly={!isRegistrationAuthority || isClosedForever}
        />
      </PageContent>
    </LoadingOverlay>
  );
};

export default memo(InstitutionDetailsPage);
