import {DataTable, LoadingOverlay, PageContent} from "@nbp/dnafe-material-ui/dist/components";
import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEvent} from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import moment from "moment";
import {useForm} from "react-hook-form";
import {Alert} from "@mui/material";
import {FORMAT_DATE_SERVER} from "../constants/date";
import {usePageTitle} from "../hooks/usePageTitle";
import {useUserMeData} from "../hooks/user";
import {getAnalysisByInstitution, useAnalysisByInstitution, useAnalysisByInstitutionLoading} from "../hooks/analisys";
import {getBreadcrumbValues, setBreadcrumbValues} from "../hooks/general";
import PageHeader from "../components/Layout/PageHeader/PageHeader";
import {
  getInstitutionsIdNames,
  getMyAdminInstitution,
  useInstitutionsIdNamesData,
  useInstitutionsIdNamesLoading
} from "../hooks/institution";
import {InstitutionAnalysisResponse} from "../api";
import {DataTableColumn} from "@nbp/dnafe-material-ui/dist/components/DataTable/DataTable";
import AnalysisDateRangeFilter from "../components/analysis/AnalysisDateRangeFilter";

const getDefaultValues = () => ({
  startDate: moment().subtract(1, "month").startOf("month").format(FORMAT_DATE_SERVER),
  endDate: moment().startOf("month").subtract(1, "day").format(FORMAT_DATE_SERVER)
});

interface InstitutionAnalysisPageRef {
  institutionLoaded?: boolean;
  registrationAuthorityLoaded?: boolean;
  dataLoaded?: boolean;
}

const InstitutionAnalysisPage = () => {
  usePageTitle("menu.analysis");
  const {registrationAuthorityId, institutionId} = useParams();
  const {t, i18n: {language}} = useTranslation();

  const userMe = useUserMeData();
  const institutionsIdNamesLoading = useInstitutionsIdNamesLoading();
  const institutionsIdNamesData = useInstitutionsIdNamesData();
  const institution = useMemo(() => getMyAdminInstitution(institutionId), [institutionId, userMe]);
  const name = (institution ? institution?.institution?.name : institutionsIdNamesData?.institutionName) ?? "";
  const defaultValues = getDefaultValues();
  const analysisByInstitutionLoading = useAnalysisByInstitutionLoading();
  const analysisByInstitution = useAnalysisByInstitution();
  const [error, setError] = useState(false);
  const ref = useRef<InstitutionAnalysisPageRef>({});

  const {control, getValues, reset, trigger} = useForm({defaultValues});

  const loader = useEvent(async () => {
    setError(false);
    try {
      const valid = await trigger().catch(console.error);
      if (valid) {
        const {startDate, endDate} = getValues();
        return await getAnalysisByInstitution({
          startDate,
          endDate,
          institutionId
        }).catch(console.error);
      }
    } catch (error) {
      console.error(error);
    }
    setError(true);
    return null;
  });

  const setDateRange = useEvent((startDate: string, endDate: string) => {
    reset({startDate, endDate});
    loader();
  });

  useEffect(() => {
    if (institutionId && !ref.current.dataLoaded) {
      loader();
      ref.current.dataLoaded = true;
    }
  }, [institutionId]);

  useEffect(() => {
    if (institutionId && userMe && !ref.current.institutionLoaded) {
      if (!institution?.institution) {
        getInstitutionsIdNames({id: institutionId}).then(data => {
          if (data?.institutionName) {
            setBreadcrumbValues({...getBreadcrumbValues(), [institutionId]: data.institutionName});
            setBreadcrumbValues({...getBreadcrumbValues(), [registrationAuthorityId]: data.registrationAuthorityName});
          }
        }).catch(console.error);
      }
      ref.current.institutionLoaded = true;
    }
  }, [institutionId, userMe]);

  const columns: DataTableColumn<InstitutionAnalysisResponse>[] = useMemo(() => [
    {
      name: "certificateType",
      headerClassName: "number-column-header",
      title: t("analysis.certificateType.label"),
      template: ({certificateType}) => t("analysis.certificateType." + certificateType)
    },
    {
      name: "numberOfSignatures",
      headerClassName: "number-column-header",
      title: t("analysis.numberOfSignatures")
    },
    {
      name: "numberOfRevocations",
      headerClassName: "number-column-header",
      title: t("analysis.numberOfRevocations")
    }
  ], [language]);

  const loading = institutionsIdNamesLoading || analysisByInstitutionLoading;

  return (
    <LoadingOverlay className="InstitutionAnalysisPage" loading={loading}>
      <PageHeader title={name ?? t("institution.title")} subTitleMuted={t("menu.analysis")}/>
      <PageContent>
        <div className="filter-row flex-row flex-wrap flex-gap margin-bottom-m">
          <AnalysisDateRangeFilter
            setDateRange={setDateRange} control={control} defaultValues={defaultValues} getValues={getValues}
            onChange={loader}
          />
        </div>
        {error && <Alert className="margin-bottom-m" color="error">
          {t("analysis.filterInvalid")}
        </Alert>}
        <DataTable data={analysisByInstitution} columns={columns} emptyText={t("main.empty")}/>
      </PageContent>
    </LoadingOverlay>
  );
};

export default memo(InstitutionAnalysisPage);
