import {AddressRequest, InstitutionRequest, InstitutionRequestClosingReason} from "../api";
import { ADDRESS_ROLE_MAIN } from "../constants/institution";

interface InstitutionRequestFormValues {
  name?: string;
  state?: string;
  countryCode?: string;
  type?: string;
  registrationAuthorityId?: string;
  openingDate?: string;
  closingDate?: string;
  closingReason?: InstitutionRequestClosingReason;
  street?: string;
  postalCode?: string;
  city?: string;
  addressId?: string;
  phone?: string;
  fax?: string;
  email?: string;
  website?: string;
}

export class InstitutionRequestData implements InstitutionRequest {
  name?: string;
  state?: string;
  countryCode?: string;
  type?: string;
  registrationAuthorityId?: string;
  openingDate?: string;
  closingDate?: string;
  closingReason?: InstitutionRequestClosingReason;
  addresses?: AddressRequest[];

  constructor(data: InstitutionRequestFormValues) {
    this.name = data.name;
    this.state = data.state;
    this.type = data.type;
    this.registrationAuthorityId = data.registrationAuthorityId;
    this.openingDate = data.openingDate;
    this.closingDate = data.closingDate;
    this.closingReason = data.closingReason;
    this.countryCode = data.countryCode;

    this.addresses = [{
      street: data.street,
      postalCode: data.postalCode,
      city: data.city,
      phone: data.phone,
      fax: data.fax,
      email: data.email,
      website: data.website,
      roleOfAddress: ADDRESS_ROLE_MAIN,
      id: data.addressId
    }];
  }
}
