import {Alert, Button} from "@mui/material";
import React, {FC, memo, useState} from "react";
import {useTranslation} from "react-i18next";
import {generateInstitutionMTLSAccess, useGenerateInstitutionMTLSAccessLoading} from "../../hooks/institution";
import {useEvent} from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import {BaseDialog} from "@nbp/dnafe-material-ui/dist/components";
import {SimpleDialogProps} from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import {useAxiosErrorHandler} from "../../hooks/general";
import {showError, showSuccess} from "@nbp/dnafe-material-ui/dist/hooks/snackbar";
import FieldWithCopy from "../FieldWithCopy/FieldWithCopy";
import {MtlsAccessResponse} from "../../api";

interface InstitutionMTLSAccessDialogProps extends SimpleDialogProps {
  institutionId: string;
}

const InstitutionMTLSAccessDialog: FC<InstitutionMTLSAccessDialogProps> = ({onClose, open, institutionId}) => {
  const {t} = useTranslation();
  const generateInstitutionMTLSAccessLoading = useGenerateInstitutionMTLSAccessLoading();
  const axiosErrorHandler = useAxiosErrorHandler();
  const [data, setData] = useState<MtlsAccessResponse>(null);
  const {issuerId, trustedIssuerId, keystore, password} = data ?? {};

  const successHandler = () => {
    showSuccess(t("institution.mtls.generateSuccess"));
  };

  const onGenerate = useEvent(() => {
    generateInstitutionMTLSAccess({institutionId}).then((data) => {
      successHandler();
      setData(data);
    }).catch((error) => {
      if (error.status === 409) {
        showError(t("institution.mtls.errorNoEmail"));
      } else {
        axiosErrorHandler(error);
      }
    });
  });

  const loading = generateInstitutionMTLSAccessLoading;

  return (
    <BaseDialog
      className="InstitutionMTLSAccessDialog" open={open} onClose={onClose}
      title={t("institution.mtls.dialogTitle")}
      loading={loading} closeOnBackdropClick={!loading} closeOnEscapeKeyDown={!loading} size="large"
      actions={(<>
        <Button
          variant="outlined" size="large" onClick={onClose} disabled={loading}>
          {t("main.close")}
        </Button>
        <Button
          variant="contained" size="large" onClick={onGenerate} autoFocus disabled={loading || !open}>
          {t("institution.mtls.generateCertificate")}
        </Button>
      </>)}>
      <div>
        {!!data && <form>
          <div className="flex-row form-row flex-gap">
            <FieldWithCopy value={issuerId} label={t("institution.mtls.issuerId")} fullWidth/>
            <FieldWithCopy value={trustedIssuerId} label={t("institution.mtls.trustedIssuerId")} fullWidth/>
          </div>
          <div className="flex-row form-row flex-gap">
            <FieldWithCopy value={password} label={t("institution.mtls.password")} fullWidth/>
          </div>
          <div className="flex-row form-row flex-gap">
            <FieldWithCopy
              value={keystore} label={t("institution.mtls.keystore")} fullWidth multiline/>
          </div>
        </form>}
        <Alert severity="warning">{t("institution.mtls.generateWarningText")}</Alert>
      </div>
    </BaseDialog>
  );
};

export default memo(InstitutionMTLSAccessDialog);
