import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { usePageTitle } from "../hooks/usePageTitle";
import { useTranslation } from "react-i18next";
import { useModal } from "mui-modal-provider";
import PageHeader from "../components/Layout/PageHeader/PageHeader";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useEvent } from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import {
  DataPagination,
  DataTable,
  DataTableActionsColumn,
  LoadingOverlay,
  PageContent
} from "@nbp/dnafe-material-ui/dist/components";
import { DataTableColumn } from "@nbp/dnafe-material-ui/dist/components/DataTable/DataTable";
import { usePagedTable } from "@nbp/dnafe-material-ui/dist/hooks/usePagedTable";
import { SimpleDialogProps } from "@nbp/dnafe-material-ui/dist/components/Dialog/BaseDialog";
import { useNavigate } from "react-router-dom";
import { LINK_REGISTRATION_AUTHORITIES_ADMINISTRATION } from "../constants/navigate";
import { RegistrationAuthorityResponse } from "../api";
import {
  deleteRegistrationAuthority,
  getRegistrationAuthorityPaged,
  setRegistrationAuthorityMenuPopover,
  useDeleteRegistrationAuthorityLoading,
  useRegistrationAuthorityPaged,
  useRegistrationAuthorityPagedLoading
} from "../hooks/registrationAuthority";
import { RegistrationAuthorityMenuPopover } from "../components/registrationAuthority/RegistrationAuthorityMenuPopover";
import RegistrationAuthorityCreateDialog from "../components/registrationAuthority/RegistrationAuthorityCreateDialog";
import { FORMAT_DATE_TIME_CLIENT, FORMAT_DATE_TIME_SERVER } from "../constants/date";
import moment from "moment/moment";
import { hasPermission } from "../hooks/auth";
import { PERMISSION } from "../constants/permission";
import { showError, showSuccess } from "@nbp/dnafe-material-ui/dist/hooks/snackbar";

const RegistrationAuthoritiesAdministrationPage = () => {
  usePageTitle("menu.registrationAuthoritiesAdministration");
  const { t, i18n: { language } } = useTranslation();
  const registrationAuthorityPagedLoading = useRegistrationAuthorityPagedLoading();
  const deleteRegistrationAuthorityLoading = useDeleteRegistrationAuthorityLoading();
  const { showModal } = useModal();
  const navigate = useNavigate();
  const ref = useRef<boolean>(false);

  const loader = useEvent(async () => await getRegistrationAuthorityPaged({
    size: pageSize, page, sort: sortValue, search
  }).catch(console.error));

  useEffect(() => {
    if (!ref.current) {
      loader();
    }
    ref.current = true;
  }, []);

  const { page, setPage, pageSize, search, setSearch, pages, tableRows, sort, onSort } =
    usePagedTable<RegistrationAuthorityResponse>({
      useData: useRegistrationAuthorityPaged,
      useLoading: useRegistrationAuthorityPagedLoading,
      loader,
      defaultSort: { field: "createdAt", desc: true }
    });

  const sortValue = sort?.field ? [sort?.field, sort?.desc ? "desc" : "asc"].join(",") : undefined;

  const showAddRegistrationAuthorityDialog = useEvent(() => showModal((props: SimpleDialogProps) => (
    <RegistrationAuthorityCreateDialog {...props} onUpdate={loader} />
  )));

  const handleRowClick = useEvent((data: RegistrationAuthorityResponse) => {
    navigate(LINK_REGISTRATION_AUTHORITIES_ADMINISTRATION + "/" + data.id);
  });

  const deleteHandler = useEvent(async (id: string) => await deleteRegistrationAuthority({ id }).then(() => {
    showSuccess(t("registrationAuthority.deleteSuccess"));
    loader();
  }).catch(error => {
    if (error?.response?.status === 400) {
      showError(t("registrationAuthority.deleteError"));
    } else {
      showError(error.message);
    }
  }));

  const DataTableActionsColumnTemplate = useCallback((row: RegistrationAuthorityResponse) => (
    <DataTableActionsColumn row={row} openMenuPopover={setRegistrationAuthorityMenuPopover} />
  ), [language]);

  const columns: DataTableColumn<RegistrationAuthorityResponse>[] = useMemo(() => [
    {
      name: "name",
      title: t("registrationAuthority.name"),
      sortable: true
    },
    {
      name: "createdAt",
      headerClassName: "column-created-at",
      title: t("main.createdAt"),
      sortable: true,
      template: ({ createdAt }: RegistrationAuthorityResponse) =>
        moment(createdAt, FORMAT_DATE_TIME_SERVER).format(FORMAT_DATE_TIME_CLIENT)
    },
    hasPermission(PERMISSION.REGISTRATION_AUTHORITY_DELETE) && {
      name: "actions",
      className: "action-column",
      headerClassName: "action-column-header",
      title: t("main.actions"),
      template: DataTableActionsColumnTemplate
    }
  ].filter(item => !!item), [language]);

  const loading = registrationAuthorityPagedLoading || deleteRegistrationAuthorityLoading;

  return (
    <LoadingOverlay className="RegistrationAuthoritiesAdministrationPage" loading={loading} fullHeight>
      <PageHeader
        title={t("menu.registrationAuthoritiesAdministration")}
        searchPlaceholder={t("main.searchPlaceholder")} onSearch={setSearch}>
        {hasPermission(PERMISSION.REGISTRATION_AUTHORITY_DELETE) &&
          <Button onClick={showAddRegistrationAuthorityDialog} variant="outlined" color="primary"
                  startIcon={<AddIcon />}>
            {t("registrationAuthority.addRegistrationAuthority")}
          </Button>
        }
      </PageHeader>
      <PageContent>
        <DataTable
          data={tableRows} columns={columns}
          onRowClick={hasPermission(PERMISSION.REGISTRATION_AUTHORITY_READ) ? handleRowClick : undefined}
          sort={sort} onSort={onSort} emptyText={t("main.empty")}
        />
        {!!pages && <DataPagination pages={pages} onChange={setPage} value={page} />}
      </PageContent>
      <RegistrationAuthorityMenuPopover onDelete={deleteHandler} />
    </LoadingOverlay>
  );
};

export default memo(RegistrationAuthoritiesAdministrationPage);
