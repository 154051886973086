import React, {memo, useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useEvent} from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import PopoverMenu, {PopoverMenuItemProps} from "@nbp/dnafe-material-ui/dist/components/PopoverMenu";
import {
  setRegistrationAuthorityActionsPopover,
  useRegistrationAuthorityActionsPopover
} from "../../hooks/registrationAuthority";

interface RegistrationAuthorityActionsMenuPopoverProps {
  onOpenAnalyse: () => void;
  onOpenImport?: () => void;
}

export const RegistrationAuthorityActionsMenuPopover = (
  {onOpenAnalyse, onOpenImport}: RegistrationAuthorityActionsMenuPopoverProps
) => {
  const {t, i18n: {language}} = useTranslation();
  const popover = useRegistrationAuthorityActionsPopover();
  const anchor = popover?.anchor;

  const closeMenu = useEvent(() => setRegistrationAuthorityActionsPopover({anchor: null}));
  useEffect(closeMenu, []);

  const menuItems = useMemo<PopoverMenuItemProps[]>(() => [
    !!onOpenImport && {
      text: t("institutions.importInstitutions"),
      onClick: onOpenImport
    },
    {
      text: t("analysis.link"),
      onClick: onOpenAnalyse
    }
  ].filter(item => !!item), [language]);

  return (
    <PopoverMenu
      className="RegistrationAuthorityActionsMenuPopover"
      anchor={anchor}
      onClose={closeMenu}
      menuItems={menuItems}
    />
  );
};

export default memo(RegistrationAuthorityActionsMenuPopover);
