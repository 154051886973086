import * as React from "react";
import {createBrowserRouter} from "react-router-dom";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import LogoutPage from "./pages/LogoutPage";
import LandingPage from "./pages/LandingPage/LandingPage";
import {ProtectedRoute} from "./components/ProtectedRoute";
import {
  LINK_ANALYSIS,
  LINK_INSTITUTIONS,
  LINK_LOGOUT,
  LINK_PART_ANALYSIS,
  LINK_REGISTRATION_AUTHORITIES,
  LINK_REGISTRATION_AUTHORITIES_ADMINISTRATION
} from "./constants/navigate";
import InstitutionsPage from "./pages/InstitutionsPage";
import "./helpers/axios";
import Layout from "./components/Layout/Layout";
import InstitutionDetailsPage from "./pages/InstitutionDetailsPage";
import RegistrationAuthoritiesPage from "./pages/RegistrationAuthoritiesAdministrationPage";
import RegistrationAuthorityAdministrationDetailsPage from "./pages/RegistrationAuthorityAdministrationDetailsPage";
import {PERMISSION} from "./constants/permission";
import MyRegistrationAuthoritiesPage from "./pages/RegistrationAuthoritiesPage";
import RegistrationAuthorityDetailsPage from "./pages/RegistrationAuthorityDetailsPage";
import ImportInstitutionsPage from "./pages/ImportInstitutionsPage";
import RegistrationAuthorityAnalysisPage
  from "./pages/RegistrationAuthorityAnalysisPage/RegistrationAuthorityAnalysisPage";
import InstitutionAnalysisPage from "./pages/InstitutionAnalysisPage";
import AnalysisPage from "./pages/AnalysisPage";

export default createBrowserRouter([
  {
    path: LINK_LOGOUT,
    element: <LogoutPage/>
  },
  {
    path: "/",
    element: <ProtectedRoute><Layout/></ProtectedRoute>,
    errorElement: <ErrorPage/>,
    children: [
      {
        path: LINK_ANALYSIS + "/:registrationAuthorityId/:institutionId",
        element: <InstitutionAnalysisPage/>
      },
      {
        path: LINK_ANALYSIS + "/:registrationAuthorityId",
        element: <ProtectedRoute permission={PERMISSION.REGISTRATION_AUTHORITY_READ}>
          <RegistrationAuthorityAnalysisPage/>
        </ProtectedRoute>
      },
      {
        path: LINK_ANALYSIS,
        element: <AnalysisPage/>
      },
      {
        path: LINK_INSTITUTIONS + "/:institutionId" + LINK_PART_ANALYSIS,
        element: <InstitutionAnalysisPage/>
      },
      {
        path: LINK_INSTITUTIONS + "/:institutionId",
        element: <InstitutionDetailsPage/>
      },
      {
        path: LINK_INSTITUTIONS,
        element: <InstitutionsPage/>
      },
      {
        path: LINK_REGISTRATION_AUTHORITIES_ADMINISTRATION + "/:registrationAuthorityId",
        element: <ProtectedRoute permission={PERMISSION.REGISTRATION_AUTHORITY_LIST}>
          <RegistrationAuthorityAdministrationDetailsPage/>
        </ProtectedRoute>
      },
      {
        path: LINK_REGISTRATION_AUTHORITIES_ADMINISTRATION,
        element: <ProtectedRoute permission={PERMISSION.REGISTRATION_AUTHORITY_READ}>
          <RegistrationAuthoritiesPage/>
        </ProtectedRoute>
      },
      {
        path: LINK_REGISTRATION_AUTHORITIES + "/:registrationAuthorityId" + LINK_PART_ANALYSIS,
        element: <RegistrationAuthorityAnalysisPage/>
      },
      {
        path: LINK_REGISTRATION_AUTHORITIES + "/:registrationAuthorityId/import",
        element: <ImportInstitutionsPage/>
      },
      {
        path: LINK_REGISTRATION_AUTHORITIES + "/:registrationAuthorityId/:institutionId" + LINK_PART_ANALYSIS,
        element: <InstitutionAnalysisPage/>
      },
      {
        path: LINK_REGISTRATION_AUTHORITIES + "/:registrationAuthorityId/:institutionId",
        element: <InstitutionDetailsPage/>
      },
      {
        path: LINK_REGISTRATION_AUTHORITIES + "/:registrationAuthorityId",
        element: <ProtectedRoute>
          <RegistrationAuthorityDetailsPage/>
        </ProtectedRoute>
      },
      {
        path: LINK_REGISTRATION_AUTHORITIES,
        element: <ProtectedRoute>
          <MyRegistrationAuthoritiesPage/>
        </ProtectedRoute>
      },
      {
        path: "/",
        element: <LandingPage/>
      }
    ]
  }
], {
  basename: window.env.BASE_PATH || ""
});
