import React, {FC, memo} from "react";
import {Control, Controller} from "react-hook-form";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {
  CERTIFICATE_ANY,
  CERTIFICATE_SELF_SIGNED,
  CERTIFICATE_TRUSTED
} from "../../constants/analysis";
import {useEvent} from "@nbp/dnafe-material-ui/dist/hooks/useEvent";
import {useTranslation} from "react-i18next";

interface CertificateTypeSelectProps {
  control: Control<any>;
  onChange: () => void;
}

const CertificateTypeSelect: FC<CertificateTypeSelectProps> = ({control, onChange}) => {
  const {t} = useTranslation();

  const setCertificateTypes = useEvent((event: Event, onChangeHandler: (event: Event) => void) => {
    onChangeHandler(event);
    onChange();
  });

  return (
    <FormControl>
      <InputLabel>{t("analysis.certificateType.label")}</InputLabel>
      <Controller
        control={control} name="certificateTypes" rules={{required: true}}
        render={({field: {onChange, value}}) => (
          <Select
            label={t("analysis.certificateType.label")}
            value={value} onChange={(event) => setCertificateTypes(event, onChange)}>
            <MenuItem value={CERTIFICATE_ANY}>{t("analysis.certificateType." + CERTIFICATE_ANY)}</MenuItem>
            <MenuItem
              value={CERTIFICATE_TRUSTED}>{t("analysis.certificateType." + CERTIFICATE_TRUSTED)}</MenuItem>
            <MenuItem
              value={CERTIFICATE_SELF_SIGNED}>{t("analysis.certificateType." + CERTIFICATE_SELF_SIGNED)}</MenuItem>
          </Select>
        )}/>
    </FormControl>
  );
};

export default memo(CertificateTypeSelect);
